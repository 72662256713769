<template>
	<main class="page-container container">
		<div class="section__top mb-20">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
					>{{ $t("tariffs") }}</AppText
				>
			</div>
		</div>

		<div class="section__body" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<div class="table-block secondary-table" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
				<table>
					<thead>
						<tr>
							<th>{{ $t("servicesMenu") | toUpperCase }}</th>
							<th>{{ $t("customer") | toUpperCase }}</th>
							<th>{{ $t("provider") | toUpperCase }}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style="max-width: 300px; background: #f3f8fc">
								<AppText size="14" line-height="18" weight="700">{{ $t("shop") }}</AppText>
							</td>
							<td>
								<AppText size="14" line-height="18" class="mb-5">
									<b>0,1% от суммы сделки</b>
								</AppText>
								<AppText size="12" line-height="21" weight="700" class="color-danger">
									{{ $t("collateral") }}
									1%
								</AppText>
							</td>
							<td>
								<AppText size="14" line-height="18" class="mb-5">
									<b>0,1% от суммы сделки</b>
								</AppText>
								<AppText size="12" line-height="21" weight="700" class="color-danger">
									{{ $t("collateral") }}
									1%
								</AppText>
							</td>
							<!-- <td>
                <AppText size="14" line-height="21" class="mb-5">
                  <b>0,15% от суммы сделки</b>
                  (но не более
                  <b>10 000 сум</b>)
                </AppText>
                <AppText size="12" line-height="21" weight="700" class="color-danger">
                  от 0 до 3 % залоговые
                  средства
                </AppText>
              </td>
              <td>
                <AppText size="14" line-height="18" class="mb-5">
                  <b>0,15% от суммы сделки</b>
                  (но не более
                  <b>10 000 сум</b>)
                </AppText>
                <AppText size="12" line-height="21" weight="700" class="color-danger">
                  от 0 до 3 % залоговые
                  средства
                </AppText>
              </td>-->
						</tr>

						<tr>
							<!-- <td style="max-width: 300px; background: #f3f8fc">
								<AppText size="14" line-height="18" weight="700">{{ $t("auction") }}</AppText>
							</td> -->
							<!-- <td>
								<AppText size="14" line-height="18" class="mb-5">
									<b>0,15% от суммы сделки</b>
									(но не более
									<b>10 000 сум</b>)
								</AppText>
								<AppText size="12" line-height="21" weight="700" class="color-danger">
									{{ $t("collateral") }}
									3%
								</AppText>
							</td> -->
							<!-- <td>
								<AppText size="14" line-height="18" class="mb-5">
									<b>0,15% от суммы сделки</b>
								</AppText>
								<AppText size="12" line-height="21" weight="700" class="color-text-success2">
									{{ $t("collateral") }}
									3%
								</AppText>
							</td> -->
						</tr>
						<tr>
							<!-- <td style="max-width: 300px; background: #f3f8fc">
								<AppText size="14" line-height="18" weight="700">Отбор наилучших предложений</AppText>
							</td> -->
							<!-- <td>
								<AppText size="14" line-height="18" class="mb-5">
									<b>0,15% от суммы сделки</b>
									(но не более
									<b>10 000 сум</b>)
								</AppText>
							</td> -->
							<!-- <td>
								<AppText size="14" line-height="18" class="mb-5">
									<b>0,15% от суммы сделки</b>
									(но не более
									<b>4 млн</b>)
								</AppText>
								<AppText size="12" line-height="21" weight="700" class="color-text-success2">
									{{ $t("collateral") }}
									1-3%
								</AppText>
							</td> -->
						</tr>
						<tr>
							<!-- <td style="max-width: 300px; background: #f3f8fc">
								<AppText size="14" line-height="18" weight="700">{{ $t("tender") }}</AppText>
							</td> -->
							<!-- <td>
								<AppText size="14" line-height="18" class="mb-5">
									<b>0,15% от суммы сделки</b>
									(но не более
									<b>10 000 сум</b>)
								</AppText>
							</td> -->
							<!-- <td>
								<AppText size="14" line-height="18" class="mb-5">
									<b>0,15% от суммы сделки</b>
									(но не более
									<b>4 млн</b>)
								</AppText>
								<AppText size="12" line-height="21" weight="700" class="color-text-success2">
									{{ $t("collateral") }}
									1-5%
								</AppText>
							</td> -->
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";

export default {
	name: "index",
	components: { AppText },
};
</script>

<style lang="scss" scoped>
.table-block {
	font-family: "MazzardHSemibold";
}
.secondary-table {
	text-align: center;
	background: #fafdff;

	th {
		background: var(--color-success2);
		color: white;
		text-align: center;
		padding: 22px;
		border-right: 1px solid #45bacb;
	}
}
</style>
